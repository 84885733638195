// extracted by mini-css-extract-plugin
export var alignLeft = "m_qh d_fp d_bG d_dv";
export var alignCenter = "m_bP d_fq d_bD d_dw";
export var alignRight = "m_qj d_fr d_bH d_dx";
export var comparisonContainer = "m_qk d_dX";
export var comparisonContainerFull = "m_ql d_dV";
export var comparisonFloatyBox = "m_h1 d_h1 d_by d_Z d_cz";
export var comparisonSubtitle = "m_jb d_jb d_w d_c5";
export var comparisonRow = "m_h6 d_h6 d_cc d_bJ";
export var comparisonMainHeader = "m_h7 d_h7 d_w d_cw";
export var comparisonComponentText = "m_h3 d_h3 d_w";
export var comparisonBtnWrapper = "m_jj d_jj d_d1 d_w d_bz";
export var comparisonBtnWrapperSecond = "m_jk d_jk d_w";
export var comparisonImageContainer = "m_jg d_jg d_Z d_by d_w";
export var stretch = "m_qm";
export var limit = "m_qn";
export var exceptionWeight = "m_qp p_qT";