// extracted by mini-css-extract-plugin
export var carouselContainer = "F_vz d_w d_H d_bf d_Z";
export var carouselContainerCards = "F_vB F_vz d_w d_H d_bf d_Z";
export var carouselContainerSides = "F_vC d_w d_H d_Z";
export var prevCarouselItem = "F_vD d_w d_H d_0 d_k";
export var prevCarouselItemL = "F_vF F_vD d_w d_H d_0 d_k";
export var moveInFromLeft = "F_vG";
export var prevCarouselItemR = "F_vH F_vD d_w d_H d_0 d_k";
export var moveInFromRight = "F_vJ";
export var selectedCarouselItem = "F_vK d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "F_vL F_vK d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "F_vM F_vK d_w d_H d_Z d_bf";
export var nextCarouselItem = "F_vN d_w d_H d_0 d_k";
export var nextCarouselItemL = "F_vP F_vN d_w d_H d_0 d_k";
export var nextCarouselItemR = "F_vQ F_vN d_w d_H d_0 d_k";
export var arrowContainer = "F_vR d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "F_vS F_vR d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "F_vT F_vS F_vR d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "F_vV F_vR d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "F_vW";
export var nextArrowContainerHidden = "F_vX F_vV F_vR d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "F_kG d_0";
export var prevArrow = "F_vY F_kG d_0";
export var nextArrow = "F_vZ F_kG d_0";
export var carouselIndicatorContainer = "F_v0 d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "F_v1 d_w d_bz d_bF";
export var carouselText = "F_v2 d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "F_v3 F_v2 d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "F_v4 d_b7";
export var carouselIndicator = "F_v5 F_v4 d_b7";
export var carouselIndicatorSelected = "F_v6 F_v4 d_b7";
export var arrowsContainerTopRight = "F_v7 d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "F_v8 d_0 d_bl d_bC";
export var arrowsContainerSides = "F_v9 d_0 d_bl d_bC";
export var smallArrowsBase = "F_wb d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "F_wc F_wb d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "F_wd F_wc F_wb d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "F_wf F_wb d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "F_wg F_wf F_wb d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "F_p9";
export var iconSmall = "F_wh";
export var multipleWrapper = "F_wj d_bC d_bF d_bf";
export var multipleImage = "F_wk d_bC";
export var sidesPrevContainer = "F_wl F_wc F_wb d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "F_wm F_wc F_wb d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";