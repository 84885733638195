// extracted by mini-css-extract-plugin
export var alignDiscLeft = "s_sl d_fp d_bG d_dv";
export var alignLeft = "s_qh d_fp d_bG d_dv";
export var alignDiscCenter = "s_sm d_fq d_bD d_dw";
export var alignCenter = "s_bP d_fq d_bD d_dw";
export var alignDiscRight = "s_sn d_fr d_bH d_dx";
export var alignRight = "s_qj d_fr d_bH d_dx";
export var footerContainer = "s_sp d_dW d_bW";
export var footerContainerFull = "s_sq d_dT d_bW";
export var footerHeader = "s_kf d_kf";
export var footerTextWrapper = "s_sr d_w";
export var footerDisclaimerWrapper = "s_km d_km d_ck";
export var badgeWrapper = "s_ss d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "s_st d_bz d_bJ d_bN d_bL";
export var wide = "s_sv d_cy";
export var right = "s_sw d_bK";
export var line = "s_fk d_fl d_cv";
export var badgeDisclaimer = "s_sx d_bC d_bP d_bJ";
export var badgeContainer = "s_sy d_bz d_bH d_bP";
export var badge = "s_sz";
export var padding = "s_sB d_c7";
export var end = "s_sC d_bH";
export var linkWrapper = "s_sD d_dB";
export var link = "s_mC d_dB";
export var colWrapper = "s_sF d_cx";
export var consent = "s_f d_f d_bC d_bP";
export var disclaimer = "s_sG d_bz d_bJ";
export var media = "s_sH d_bx d_dy";
export var itemRight = "s_sJ";
export var itemLeft = "s_sK";
export var itemCenter = "s_sL";
export var exceptionWeight = "s_qp p_qT";