// extracted by mini-css-extract-plugin
export var customText = "r_sh d_dv d_cs d_cg";
export var videoIframeStyle = "r_pS d_d5 d_w d_H d_by d_b1 d_R";
export var customImageWrapper = "r_sj d_cs d_cg d_Z";
export var customRow = "r_qb d_bD d_bf";
export var quoteWrapper = "r_gQ d_bC d_bP d_cs d_cg";
export var quoteBar = "r_pX d_H";
export var masonryImageWrapper = "r_p3";
export var title = "r_sk";
export var Title3Small = "r_q3 p_q3 p_qr p_qz";
export var Title3Normal = "r_q4 p_q4 p_qr p_qB";
export var Title3Large = "r_q5 p_q5 p_qr p_qC";