// extracted by mini-css-extract-plugin
export var alignLeft = "t_qh d_bG";
export var alignCenter = "t_bP d_bD";
export var alignRight = "t_qj d_bH";
export var textAlignLeft = "t_sM";
export var textAlignCenter = "t_sN";
export var textAlignRight = "t_sP";
export var hoursInnerWrapperAlt = "t_sQ d_fb d_P d_Z d_bz d_bD d_bP d_dj";
export var hoursInnerWrapperLeft = "t_sR d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5 d_dj";
export var hoursText = "t_sS d_dw";
export var titleMargin = "t_sT d_cw";
export var hoursInnerInnerWrapper = "t_sV d_cz";