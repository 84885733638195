// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "x_tj d_bD";
export var storyRowWrapper = "x_hx d_hx d_bK";
export var storyLeftWrapper = "x_tk d_bz d_bP";
export var storyWrapperFull = "x_tl d_cD";
export var storyWrapperFullLeft = "x_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "x_mv d_hy";
export var storyLeftWrapperCenter = "x_tm d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "x_tn d_hF";
export var storyHeader = "x_tp d_hD d_w d_cs";
export var storyHeaderCenter = "x_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "x_hB d_hB d_by d_dw";
export var storyBtnWrapper = "x_tq d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "x_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "x_tr d_fg d_Z";
export var imageWrapperFull = "x_ts d_w d_H d_bf d_Z";
export var SubtitleSmall = "x_qd p_qd p_qr p_qD";
export var SubtitleNormal = "x_qf p_qf p_qr p_qF";
export var SubtitleLarge = "x_qg p_qg p_qr p_qG";
export var textLeft = "x_dv";
export var textCenter = "x_dw";
export var textRight = "x_dx";